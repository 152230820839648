<template>
  <v-card
    v-if="$can('member_level_list','member_level')"
    id="holiday-common"
  >
    <v-card-title
      v-if="$can('member_level_save','member_level')"
      class="d-flex align-center flex-wrap pb-0"
    >
      <div class="d-flex align-center pb-5">
        <!-- create invoice -->
        <v-btn
          color="primary"
          class="me-3"
          @click="editItem()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>新增</span>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :footer-props="{
          itemsPerPageAllText: '全部',
          itemsPerPageText: '每页显示 ',
          showCurrentPage: true
        }"
        disable-filtering
        :headers="getHeader()"
        :items="memberLevelList"
        :items-per-page="pages.itemsPerPage"
        :server-items-length="pages.serverItemsLength"
        no-data-text="无数据"
        @pagination="loadPage"
      >
        <template
          #[`item.discount`]="{ item }"
        >
          <span>{{ item.discount }}%</span>
        </template>
        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <v-tooltip
            v-if="$can('member_level_edit','member_level')"
            top
          >
            <template #activator="{ on, attrs }">
              <v-icon
                small
                class="me-2"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <span>编辑</span>
          </v-tooltip>
          <v-tooltip
            v-if="$can('member_level_delete','member_level')"
            top
          >
            <template #activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                @click="showDeleteDialog(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <span>删除</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog
      v-model="deleteDialog"
      width="300"
    >
      <v-card>
        <v-card-text> 删除<span style="color: red">{{ deleteData.levelName }}</span>, 将清空对应用户等级 </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItem()"
          >
            确认删除
          </v-btn>
          <v-btn
            color="normal"
            text
            @click="deleteDialog = false"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 750 : '100%'"
      app
    >
      <v-card height="100%">
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}会员等级</span>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mr-5"
            :disabled="commitFlag"
            :loading="commitFlag"
            @click="handleFormSubmit()"
          >
            保存
          </v-btn>
          <v-btn
            icon
            small
            @click="editDialog = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-card-text
          style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
          class="overflow-auto"
        >
          <v-card>
            <v-form
              ref="editForm"
              lazy-validation
              @submit.prevent="handleFormSubmit"
            >
              <v-card-text class="pa-3">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="levelName"><span style="color: red">*</span> 等级名称：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        id="levelName"
                        v-model="editedItem.levelName"
                        :rules="rules.required"
                        required
                        counter="25"
                        label="会员等级名称"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="discount"><span style="color: red">*</span>折扣率：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        id="discount"
                        v-model="editedItem.discount"
                        required
                        :rules="rules.required"
                        label="折扣率"
                        suffix="%"
                        type="number"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-card>
</template>

<script>

import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
} from '@mdi/js'
import memberLevelApi from '@/api/ucnter/memberLevel'

export default {
  name: 'MemberLevel',
  components: {
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
      },
      editDialog: false,
      deleteDialog: false,
      rules: {
        required: [v => !!v || '该字段不能为空'],
      },
      loadings: {
        submitLoading: false,
      },
      memberLevelList: [],
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      editedItem: {},
      editedIndex: -1,
      defaultItem: {
        id: undefined,
        levelName: '',
        discount: '',
      },
      deleteData: {},
      commitFlag: false,
    }
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: 'ID', sortable: false, value: 'id' },
        { text: '会员名称', value: 'levelName' },
        { text: '折扣率', value: 'discount' },
      ]

      if (this.$can('member_level_edit', 'member_level')
          || this.$can('member_level_delete', 'member_level')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    loadPage({ page, itemsPerPage }) {
      const searchData = {
        rows: itemsPerPage,
        page,
      }
      memberLevelApi.getPages(searchData)
        .then(response => {
          const { data } = response.data
          this.memberLevelList = data.records
          if (data.size === -1) {
            this.pages.serverItemsLength = this.memberLevelList.length
          } else {
            this.pages.serverItemsLength = data.total
          }
        })
    },
    editItem(item) {
      if (item) {
        this.editedIndex = this.memberLevelList.indexOf(item)

        this.editedItem = item
      } else {
        this.editedIndex = -1
        this.editedItem = this.defaultItem
        this.$nextTick(() => {
          this.$refs.editForm.reset()
        })
      }
      this.commitFlag = false
      this.editDialog = true
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return

      if (this.commitFlag) return

      this.loadings.submitLoading = true
      const submitData = {
        ...this.editedItem,
      }
      this.commitFlag = true
      memberLevelApi.saveOrUpdate(submitData)
        .then(response => {
          this.$toast.success('保存成功')

          // if (this.editedIndex > -1) {
          //   Object.assign(this.memberLevelList[this.editedIndex], this.editedItem)
          // } else {
          //   const obj = {
          //     id: response.data.data,
          //     levelName: this.editedItem.levelName,
          //     discount: this.editedItem.discount,
          //   }

          //   this.memberLevelList.unshift(obj)
          // }
          this.editDialog = false
        })
        .finally(() => {
          this.loadPage(this.pages)
          this.commitFlag = false
          this.loadings.submitLoading = false
        })
    },
    showDeleteDialog(item) {
      this.deleteData = item
      this.deleteDialog = true
    },
    deleteItem() {
      memberLevelApi
        .delete(this.deleteData.id)
        .then(() => {
          this.$toast.success('删除成功！')
          this.deleteData = {}
          this.loadPage(this.pages)
          this.deleteDialog = false
        })
    },
  },
}
</script>

<style scoped>

</style>
